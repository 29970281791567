import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Row,
  Col,
  Button,
  message,
  Layout,
  InputNumber,
  ConfigProvider,
} from "antd";
import {
  fetchTokenImages,
  getRoute,
  getSwapTokens,
  getTokenImage,
} from "../api/ApiCalls";
import { useAccount, useChainId } from "wagmi";
import Web3 from "web3";
import {
  getSwapRouterAllowance,
  getTokenBalance,
} from "../utils/poolFunctions";
import { useWeb3Modal } from "@web3modal/wagmi/react";

import { Token } from "@shidoglobal/sdk-core";
import BigNumber from "bignumber.js";
import { fromReadableAmount } from "../utils/tradeUtils";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import ArrowDown from "../assets/Svg/FiArrowDown.svg";
import { executeRoute, setSwapRouterApproval } from "../utils/trading";
import { useDebounce } from "../hooks/useDebounce";
import arrowUp from "../assets/ArrowUp.svg";
import arrowDown from "../assets/ArrowDown.svg";
import slippageIcon from "../assets/slippage.svg";
import { LOAD_SWAP_TOKENS } from "../graphql";
import { useQuery } from "@apollo/client";
import { useError } from "../contexts/ErrorContext";
import { DownOutlined } from "@ant-design/icons";
import SelectSwapTokenModal from "../components/SelectSwapTokenModal";
import defaultSVG from "../assets/DefaultSVG.svg";
import { formatNumberUniversal } from "../utils";

const Swap = () => {
  const { setError } = useError();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [fromCurrency, setFromCurrency] = useState<any>(null);
  const [toCurrency, setToCurrency] = useState<any>(null);
  const [fromAmount, setFromAmount] = useState<any>(1);
  const [toAmount, setToAmount] = useState<any>(0);
  const [slippage, setSlippage] = useState<any>(0.5);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenInBalance, setTokenInBalance] = useState<string>();
  const [tokenOutBalance, setTokenOutBalance] = useState<string>();
  const [token0Image, setToken0Image] = useState<string>(defaultSVG);
  const [token1Image, setToken1Image] = useState<string>(defaultSVG);
  const [swapRoute, setSwapRoute] = useState<any>(null);
  const [routeString, setRouteString] = useState<string>("");
  const { connector, address } = useAccount();
  const chanId = useChainId();
  const [loadingMessage, setLoadingMessage] = useState("");
  // debounced values
  const [debouncedFromAmount, setDebouncedFromAmount] = useState(0);
  const fromAmountDebounce = useDebounce(fromAmount, 1000);

  const [debouncedToAmount, setDebouncedToAmount] = useState(0);
  const toAmountDebounce = useDebounce(toAmount, 1000);

  const isFromAmountManual = useRef(false);
  const isToAmountManual = useRef(false);

  const [selectFromTokenModalOpen, setSelectFromTokenModalOpen] =
    useState(false);

  const [selectToTokenModalOpen, setSelectToTokenModalOpen] = useState(false);

  const { open } = useWeb3Modal();

  const getTokenImages = async () => {
    try {
      const tokenImages = await fetchTokenImages([
        fromCurrency?.id,
        toCurrency?.id,
      ]);
      setToken0Image(
        tokenImages?.[Web3.utils.toChecksumAddress(fromCurrency?.id)]
      );
      setToken1Image(
        tokenImages?.[Web3.utils.toChecksumAddress(toCurrency?.id)]
      );
    } catch (error) {
      console.error("Error fetching token images", error);
    }
  };

  useEffect(() => {
    getTokenImages();
  }, [fromCurrency, toCurrency]);

  useEffect(() => {
    const loadInitialTokens = async () => {
      const tokens = await getSwapTokens();

      if (tokens && tokens.length >= 2) {
        setFromCurrency(tokens[0]);
        setToCurrency(tokens[1]);
      }
    };

    loadInitialTokens();
  }, []);

  useEffect(() => {
    setDebouncedFromAmount(fromAmount);
  }, [fromAmountDebounce]);

  useEffect(() => {
    setDebouncedToAmount(toAmount);
  }, [toAmountDebounce]);

  useEffect(() => {
    if (debouncedFromAmount && isFromAmountManual.current) {
      setToAmount(null);
      loadSwapRoute(debouncedFromAmount);
      isFromAmountManual.current = false;
    }
  }, [debouncedFromAmount]);

  useEffect(() => {
    if (debouncedToAmount && isToAmountManual.current) {
      setFromAmount(null);
      loadSwapRoute(debouncedToAmount, "exactOut");
      isToAmountManual.current = false;
    }
  }, [debouncedToAmount]);

  // end of debounce
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshBalances();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    refreshBalances();
  }, [fromCurrency, toCurrency]);

  const refreshBalances = async () => {
    try {
      const provider = new Web3(await connector?.getProvider());

      if (!address || !provider) {
        return;
      }

      if (fromCurrency?.id) {
        const balance = (await getTokenBalance(
          address,
          fromCurrency?.id
        )) as string;

        setTokenInBalance(balance);
      }
      if (toCurrency?.id) {
        const balance = (await getTokenBalance(
          address,
          toCurrency?.id
        )) as string;

        setTokenOutBalance(balance);
      }
    } catch (error: any) {
      setError({
        message: "Error occurred while fetching balances",
        description: error.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  const onSwap = async () => {
    try {
      setLoading(true);
      setLoadingMessage("Swap in progress...");
      if (fromCurrency?.id && toCurrency?.id) {
        const web3 = new Web3(await connector?.getProvider());

        const tokenA = new Token(
          Number(chanId),
          fromCurrency.id,
          Number(fromCurrency.decimals),
          fromCurrency.symbol,
          fromCurrency.name
        );

        const decimatedAmount = fromReadableAmount(
          fromAmount,
          tokenA.decimals
        ).toString();

        //   check tokenA SwapRouter Allowance allowance
        const allowance: BigNumber = await getSwapRouterAllowance(
          address,
          tokenA
        );

        if (allowance.lt(BigNumber(decimatedAmount))) {
          // maximum possible value for a 256-bit unsigned integer.
          const maxApprovalValue = new BigNumber(
            "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
          );
          const scaledValue = maxApprovalValue
            .multipliedBy(new BigNumber(10).pow(18))
            .integerValue();

          // get approve router allowance here
          const approvalStatus = await setSwapRouterApproval(
            tokenA,
            web3,
            scaledValue.toFixed(),
            address
          );

          if (!approvalStatus && !approvalStatus.transactionHash) {
            setError({
              message: "Please increase router contract allowance",
            });
            setLoading(false);
            setLoadingMessage("");
            return;
          }
        }

        if (swapRoute && swapRoute.methodParameters) {
          const res = await executeRoute(
            web3,
            tokenA.address,
            decimatedAmount,
            swapRoute,
            address
          );

          setError({
            message: "Swap successful",
            type: "success",
          });

          refreshBalances();
        }
      }

      setLoading(false);
      setLoadingMessage("");
    } catch (e: any) {
      setError({
        message: e.message,
        type: "error",
      });
      setLoading(false);
      setLoadingMessage("");
    }
  };

  const loadSwapRoute = async (amount: any, swapType = "exactIn") => {
    setLoading(true);
    setLoadingMessage("Fetching Best Route...");

    try {
      if (fromCurrency?.id && toCurrency?.id) {
        const route = await getRoute(
          fromCurrency.id,
          toCurrency.id,
          amount > 0 ? amount : 1,
          address,
          swapType,
          slippage
        );

        if (!route.statusCode) {
          if (swapType === "exactIn") {
            setToAmount(Number(route.quoteDecimals));
          } else {
            setFromAmount(Number(route.quoteDecimals));
          }

          setSwapRoute(route);
          setRouteString("");
        } else {
          setSwapRoute(null);
          setRouteString("No route found");
        }
      }
    } catch (error: any) {
      setError({
        message: "Error loading swap route",
        description: error.message,
        type: "error",
      });
    }
    setLoading(false);
    setLoadingMessage("");
  };

  const handleFromAmountChange = (value: any) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }

    isFromAmountManual.current = true;

    const amount = value;
    setFromAmount(amount);
  };

  const handleToAmountChange = (value: any) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }

    isToAmountManual.current = true;

    const amount = value;
    setToAmount(amount);
    setFromAmount(null);
  };

  const isSwapDisabled = !fromCurrency || !toCurrency || !fromAmount;

  const switchTokens = () => {
    if (toCurrency && fromCurrency) {
      const newFromCurrency = toCurrency;
      const newToCurrency = fromCurrency;

      setFromCurrency(newFromCurrency);
      setToCurrency(newToCurrency);

      setToAmount(fromAmount);
      setFromAmount(toAmount);

      return;
    } else if (fromCurrency) {
      const newToCurrency = fromCurrency;

      setToCurrency(newToCurrency);
      return;
    } else if (toCurrency) {
      const newFromCurrency = toCurrency;

      setToCurrency(newFromCurrency);
      setToAmount(fromAmount);
      setFromAmount(toAmount);
      return;
    }
  };

  // use effects for change of currency

  useEffect(() => {
    if (fromAmount && toAmount) {
      setToAmount(null);
      loadSwapRoute(fromAmount);
    } else {
      setToAmount(null);
      loadSwapRoute(1);
    }
  }, [fromCurrency, toCurrency, slippage]);

  const setFromToken = (token: any) => {
    setFromCurrency(token);
  };

  const setToToken = (token: any) => {
    setToCurrency(token);
  };

  return (
    <>
      <Layout
        style={{
          marginTop: "20px",
          backgroundColor: "#252527",
          borderRadius: "12px",
          padding: sm ? "36px" : "12px",
          alignItems: "center",
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              InputNumber: {
                colorBgContainer: "transparent",
                colorBorder: "transparent",
                inputFontSizeSM: 18,
                fontSize: 18,
              },
              Select: {
                fontSizeSM: 18,
                fontSize: 18,
                optionFontSize: 14,
                colorPrimary: "transparent",
                colorPrimaryHover: "transparent",
                controlOutline: "transparent",
                //optionSelectedColor: "red",
              },
            },
          }}
        >
          <Row style={{ justifyContent: "center", marginBottom: "24px" }}>
            <Text size="lg">Swap</Text>
          </Row>
          <Row
            style={{
              padding: xs ? "14px" : "24px",
              width: xxl ? "720px" : md ? "580px" : xs ? "347px" : "488px",
              backgroundColor: "#37373C",
              borderRadius: "20px",
            }}
          >
            {/* /////////////////////////////////////////////////// */}
            <Row style={{ width: "100%" }}>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenInBalance ? Number(tokenInBalance).toFixed(6) : "0"}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenInBalance &&
                    handleFromAmountChange(Number(tokenInBalance))
                  }
                >
                  Max
                </Button>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#252527",
                  padding: "18px 16px",
                  marginTop: "12px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={token0Image}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap from
                    </Text>
                    <Button
                      size="small"
                      style={{
                        background: "rgb(37, 37, 39)",
                        color: "#ffffff",
                        border: 0,
                      }}
                      onClick={() => setSelectFromTokenModalOpen(true)}
                    >
                      {fromCurrency?.symbol}
                      <DownOutlined />
                    </Button>
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={formatNumberUniversal(fromAmount)}
                    onChange={handleFromAmountChange}
                    variant="borderless"
                    controls={false}
                    className="SwapInputNumber"
                    style={{ width: sm ? "150px" : "90px" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#3B87F7",
                    position: "absolute",
                    top: "-14px",
                    cursor: "pointer",
                  }}
                >
                  <img src={ArrowDown} onClick={switchTokens} />
                </div>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#252527",
                  padding: "18px 16px",
                  marginTop: "24px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={token1Image}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap To
                    </Text>

                    <Button
                      size="small"
                      style={{
                        background: "rgb(37, 37, 39)",
                        color: "#ffffff",
                        border: 0,
                      }}
                      onClick={() => setSelectToTokenModalOpen(true)}
                    >
                      {toCurrency?.symbol}
                      <DownOutlined />
                    </Button>
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={formatNumberUniversal(toAmount)}
                    onChange={handleToAmountChange}
                    variant="borderless"
                    className="SwapInputNumber"
                    controls={false}
                    style={{ width: sm ? "150px" : "90px", color: "#ffffff" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                  marginTop: "12px",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenOutBalance ? Number(tokenOutBalance).toFixed(6) : 0}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenOutBalance &&
                    handleToAmountChange(Number(tokenOutBalance))
                  }
                >
                  Max
                </Button>
              </Row>
            </Row>

            <SlippageTolerance setSlippage={setSlippage} slippage={slippage} />
            {/* /////////////////////////////////////////////////// */}
            <Row
              style={{
                width: "100%",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              {toCurrency &&
                fromCurrency &&
                toAmount > 0 &&
                fromAmount > 0 &&
                !loading &&
                swapRoute && (
                  <Text
                    size="md"
                    style={{ color: " #9B9CA3", fontWeight: "500" }}
                  >
                    1 {fromCurrency?.symbol} ={" "}
                    {BigNumber(
                      Number(Number(toAmount) / Number(fromAmount)).toFixed(6)
                    ).toString()}{" "}
                    {toCurrency?.symbol}
                  </Text>
                )}
            </Row>

            {address ? (
              <Button
                type="primary"
                style={{ marginTop: "24px", width: "100%" }}
                disabled={
                  isSwapDisabled ||
                  !swapRoute ||
                  fromAmount > (tokenInBalance ?? 0)
                }
                onClick={() => onSwap()}
                loading={loading}
              >
                {" "}
                {loading
                  ? loadingMessage
                  : fromAmount > (tokenInBalance ?? 0)
                  ? "Insufficient Balance"
                  : "Swap"}
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: "24px", width: "100%" }}
                onClick={() => {
                  open({ view: "Networks" });
                }}
              >
                Connect Wallet
              </Button>
            )}
            <Row
              style={{
                width: "100%",
              }}
            >
              {!loading && (
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Text size="sm" style={{ marginTop: "15px" }}>
                    {routeString}
                  </Text>{" "}
                </Col>
              )}
            </Row>
          </Row>
        </ConfigProvider>

        <SelectSwapTokenModal
          setToken={setFromToken}
          disabledToken={toCurrency}
          isOpen={selectFromTokenModalOpen}
          onClose={() => setSelectFromTokenModalOpen(false)}
        />

        <SelectSwapTokenModal
          setToken={setToToken}
          disabledToken={fromCurrency}
          isOpen={selectToTokenModalOpen}
          onClose={() => setSelectToTokenModalOpen(false)}
        />
      </Layout>
    </>
  );
};

export default Swap;

const SlippageTolerance = ({ slippage, setSlippage }: any) => {
  const [expand, setExpand] = useState<boolean>(false);
  return (
    <Row
      style={{
        marginTop: "18px",
        padding: "16px",
        borderRadius: "12px",
        border: "1px solid #7C7C82",
        width: "100%",
      }}
    >
      <Row
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <img src={slippageIcon} alt="Slippage Icon" />
          <Text size="sm" style={{ fontWeight: "600" }}>
            Slippage tolerance
          </Text>
        </Col>

        <Col style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Text size="sm" style={{ fontWeight: "600" }}>
            {slippage}%
          </Text>
          <img
            src={expand ? arrowUp : arrowDown}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setExpand(!expand);
            }}
            alt="Toggle Arrow"
          />
        </Col>
      </Row>

      {expand && (
        <Row style={{ marginTop: "24px", width: "100%", alignItems: "center" }}>
          <button
            style={{
              borderRadius: "8px",
              padding: "6px 16px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: slippage === 0.5 ? "#3B87F7" : "#515155",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
              borderColor: "transparent",
            }}
            onClick={() => {
              setSlippage(0.5);
            }}
          >
            Auto
          </button>
          <Col
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <ValueButton
              value="0.1"
              onClick={() => {
                setSlippage(0.1);
              }}
              slippage={slippage}
            />
            <ValueButton
              value="0.5"
              onClick={() => {
                setSlippage(0.5);
              }}
              slippage={slippage}
            />
            <ValueButton
              value="1"
              onClick={() => {
                setSlippage(1);
              }}
              slippage={slippage}
            />
            <input
              type="number"
              className="custom-input-slippage"
              style={{
                borderRadius: "8px",
                borderColor: "transparent",
                background: "#515155",
                color: "white",
                padding: "5px 10px",
                fontSize: "12px",
                fontWeight: "600",
                maxWidth: "100px",
              }}
              placeholder="Custom"
              onChange={(e) => {
                setSlippage(e.target.value);
              }}
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};

type ValueButtonType = {
  value: string;
  onClick?: () => void;
  slippage: number;
};
const ValueButton = (props: ValueButtonType) => {
  const { onClick, value, slippage } = props;
  return (
    <div
      style={{
        padding: "6px 12px",
        borderRadius: "8px",
        background: slippage === Number(value) ? "#3B87F7" : "#515155",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Text size="sm" style={{ fontWeight: "600" }}>
        {value} {value !== "Custom" ? "%" : ""}
      </Text>
    </div>
  );
};
