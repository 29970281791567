import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { LOAD_POOL_DAY_DATA, LOAD_POOL_HOUR_DATA } from "../graphql";
import { useQuery } from "@apollo/client";
import { Row } from "antd";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const PriceChart = ({ pool, priceType }: any) => {
  const [data, setData] = useState([]);
  const [labels, setLables] = useState([]);

  const dayDataQuery = useQuery(LOAD_POOL_DAY_DATA, {
    variables: { id: pool.id },
  });

  useEffect(() => {
    if (!dayDataQuery.loading) {
      const backendLabels = dayDataQuery.data.poolDayDatas.map((entry: any) =>
        new Date(entry.date * 1000).toLocaleDateString()
      );
      setLables(backendLabels);
      setData(dayDataQuery.data.poolDayDatas);
    }
  }, [dayDataQuery]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: `${
          priceType === pool.token0.symbol
            ? pool.token0.symbol
            : pool.token1.symbol
        } Price`,
        data: data.map((entry: any) =>
          priceType === pool.token0.symbol
            ? entry.token0Price
            : entry.token1Price
        ),
        borderColor: "rgb(59, 135, 247)",
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
          gradient.addColorStop(0, "rgba(59, 135, 247, 0.5)");
          gradient.addColorStop(1, "rgba(59, 135, 247, 0)");
          return gradient;
        },
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Price",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default PriceChart;
