import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { LOAD_POOL_DAY_DATA, LOAD_POOL_HOUR_DATA } from "../graphql";
import { useQuery } from "@apollo/client";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const VolumeChart = ({ pool }: any) => {
  const [data, setData] = useState([]);
  const [labels, setLables] = useState([]);

  const dayDataQuery = useQuery(LOAD_POOL_DAY_DATA, {
    variables: { id: pool.id },
  });

  useEffect(() => {
    if (!dayDataQuery.loading) {
      const backendLabels = dayDataQuery.data.poolDayDatas.map((entry: any) =>
        new Date(entry.date * 1000).toLocaleDateString()
      );
      setLables(backendLabels);
      setData(dayDataQuery.data.poolDayDatas);
    }
  }, [dayDataQuery]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: `Volume`,
        data: data.map((entry: any) => entry.volumeUSD),
        backgroundColor: "rgb(59, 135, 247)",
        borderColor: "rgb(59, 135, 247)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Date",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Volume ($)",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default VolumeChart;
