import {
  Col,
  ConfigProvider,
  Input,
  Modal,
  Row,
  List,
  Skeleton,
  Card,
} from "antd";

import { useState, useCallback, useEffect } from "react";

import _ from "lodash";
import Text from "./Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { fetchTokenImages, getSwapTokens } from "../api/ApiCalls";
import defaultSVG from "../assets/DefaultSVG.svg";
import { getTokenBalance } from "../utils/poolFunctions";
import { useAccount } from "wagmi";
import { formatNumberUniversal } from "../utils";
import pLimit from "p-limit";
import Web3 from "web3";

interface SelectSwapTokenModalProps {
  isOpen: boolean;
  onClose: () => void;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  disabledToken?: any;
}

type CachedBalance = {
  balance: number;
  cachedAt: number;
};

const limit = pLimit(10);
const cache = new Map<string, CachedBalance>();
const CACHE_EXPIRATION_TIME = 60 * 1000;

const SelectSwapTokenModal = (props: SelectSwapTokenModalProps) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { isOpen, onClose, setToken, disabledToken } = props;
  const [tokenBalances, setTokenBalances] = useState<{
    [key: string]: number;
  }>({});

  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<any>([]);
  const [loadingBalances, setLoadingBalances] = useState(true);
  const { address } = useAccount();
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      setSearch(query);
    }, 300),
    []
  );
  const [search, setSearch] = useState("");
  const [tokenImages, setTokenImages] = useState<any>({});

  const handleClick = (token: any) => {
    setToken(token);
    onClose();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    // search tokens
    const searchResult = async () => {
      setLoading(true);
      // search through tokens array and match search key with id, symbol and name
      const result = tokens.filter(
        (token: any) =>
          token?.id?.toLowerCase().includes(search.toLowerCase()) ||
          token?.symbol?.toLowerCase().includes(search.toLowerCase()) ||
          token?.name?.toLowerCase().includes(search.toLowerCase())
      );

      setTokens(result);

      setLoading(false);
    };
    if (search) {
      searchResult();
    } else {
      setLoading(true);
      getSwapTokens().then((data) => {
        setTokens(data);
        setLoading(false);
      });
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    getSwapTokens().then((data) => {
      setTokens(data);

      setLoading(false);
    });
  }, []);

  const fetchTokenBalances = async () => {
    setLoadingBalances(true);
    const newBalances: { [key: string]: number } = {};

    await Promise.all(
      tokens.map((token: any) =>
        limit(async () => {
          const cacheEntry = cache.get(token.id);
          const now = Date.now();
          if (cacheEntry && now - cacheEntry.cachedAt < CACHE_EXPIRATION_TIME) {
            newBalances[token.id] = Number(
              formatNumberUniversal(String(cacheEntry.balance))
            );
          } else {
            let balance = 0;
            try {
              balance = (await getTokenBalance(address, token.id)) as any;
            } catch (error) {
              console.log(error);
            }

            if (isNaN(balance)) {
              balance = 0;
            }
            cache.set(token.id, { balance, cachedAt: now });

            newBalances[token.id] = Number(
              formatNumberUniversal(String(balance))
            );
          }
        })
      )
    );

    setTokenBalances(newBalances);
    setLoadingBalances(false);
  };

  useEffect(() => {
    if (tokens.length > 0 && address) {
      fetchTokenBalances();
    }
  }, [tokens, address]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenAddresses = tokens.map((token: any) => token.id);

        const tokenImages = await fetchTokenImages(tokenAddresses);

        setTokenImages(tokenImages);
      } catch (e: any) {
        console.log(e);
      }
    };
    if (tokens.length > 0) {
      fetchData();
    }
  }, [tokens]);

  return (
    <Modal
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={false}
      centered
      width={xs ? "100%" : 500}
      styles={{
        content: {
          border: "1px solid rgba(255, 255, 255, 0.10)",
          borderRadius: "36px",
        },
      }}
    >
      <Text size="md">Select a token</Text>
      <Row
        style={{
          marginTop: "24px",
          flexDirection: "row",
          gap: "12px",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBg: "#252527",
                colorBgContainer: "#252527",
                hoverBorderColor: "#252527",
                colorBorder: "#252527",
                colorTextPlaceholder: "#7C7C82",
                borderRadiusLG: 12,
                colorText: "#fff",
              },
            },
          }}
        >
          <Input
            size="large"
            placeholder="Search by name or paste address"
            prefix={<SearchIconSvg />}
            style={{
              flexGrow: 1,
            }}
            onChange={handleSearchChange}
          />
        </ConfigProvider>
      </Row>

      <Row style={{ marginTop: "24px" }}>
        <Text size="md" color="#fff" style={{ marginBottom: "14px" }}>
          {search === "" ? "All tokens" : "Search Results"}
        </Text>
        <Row
          style={{
            maxHeight: "247px",
            width: "100%",
            overflow: "auto",
          }}
          className="custom-scrollbar-modal"
        >
          {loading ? (
            Array(5)
              .fill(null)

              .map(() => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                >
                  <div style={{}}>
                    {" "}
                    <Skeleton.Avatar active size="large" shape="circle" />
                  </div>
                  <div style={{ flex: 1 }}>
                    {" "}
                    <Skeleton.Input
                      rootClassName="loading-skeleton"
                      active
                      size="large"
                      style={{ marginLeft: "10px", width: "100%" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    {" "}
                    <Skeleton.Button
                      active
                      size="large"
                      style={{ marginLeft: "10px", width: "100%" }}
                    />
                  </div>
                </div>
              ))
          ) : tokens.length > 0 ? (
            tokens.map((token: any, index: any) => (
              <TokenList
                key={index}
                icon={""}
                name={token?.name}
                value={token?.balance || 0}
                symbol={token.symbol}
                onClick={() => {
                  handleClick(token);
                }}
                disabledToken={disabledToken}
                tokenBalance={tokenBalances[token.id] || 0}
                tokenImage={
                  tokenImages?.[Web3.utils.toChecksumAddress(token.id)]
                }
                loadingBalances={loadingBalances}
              />
            ))
          ) : (
            <Text
              size="md"
              color="secondary"
              style={{ width: "100%", textAlign: "center", color: "white" }}
            >
              No results found
            </Text>
          )}
        </Row>
      </Row>
    </Modal>
  );
};

export default SelectSwapTokenModal;

interface TokenListProp {
  name?: string;
  icon?: string;
  value?: number;
  symbol?: string;
  onClick?: () => void;
  disabledToken?: any;
  tokenBalance: any;
  loadingBalances: boolean;
  tokenImage: string | null;
}

const TokenList = (props: TokenListProp) => {
  const {
    icon,
    name,
    onClick,
    symbol,
    value,
    disabledToken,
    tokenBalance,
    loadingBalances,
    tokenImage,
  } = props;
  return (
    <Row
      className={
        disabledToken ? `token-list-component-disabled` : `token-list-component`
      }
      style={{
        padding: "6px 12px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        borderRadius: "14px",
        marginRight: "24px",
        opacity: `${symbol === disabledToken?.symbol ? "0.5" : "1"}`,
        cursor: `${symbol === disabledToken?.symbol ? "auto" : "pointer"}`,
        marginBottom: "6px",
      }}
      onClick={() => {
        if (symbol === disabledToken?.symbol) return;
        onClick?.();
      }}
    >
      <Col style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img
          src={tokenImage || defaultSVG}
          style={{
            height: "36px",
            width: "36px",
            borderRadius: "50%",
            objectFit: "contain",
          }}
        />
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Text size="sm" style={{ fontWeight: "500", fontSize: "14px" }}>
            {symbol}
          </Text>
          <Text
            size="xs"
            style={{ color: "#B6B7BF", fontWeight: "400", fontSize: "12px" }}
          >
            {name}
          </Text>
        </Col>
      </Col>
      <Text size="sm" style={{ fontWeight: "500" }}>
        {loadingBalances ? (
          <Skeleton.Button active={true} size={"small"} shape={"default"} />
        ) : (
          <>{tokenBalance}</>
        )}
      </Text>
    </Row>
  );
};

const SearchIconSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
      <path
        fill="#7C7C82"
        fillRule="evenodd"
        d="M11.5 1.75C5.85 1.75 1.25 6.35 1.25 12s4.6 10.25 10.25 10.25S21.75 17.65 21.75 12 17.15 1.75 11.5 1.75Zm0 19c-4.83 0-8.75-3.93-8.75-8.75s3.92-8.75 8.75-8.75 8.75 3.93 8.75 8.75-3.92 8.75-8.75 8.75Zm7.971.28 2 2c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-2-2a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
